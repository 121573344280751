import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Image } from 'antd';
// import List1 from 'components/ListInfo';
import Highlighter from 'react-highlight-words';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment'
import Actions from './Actions';
import authHeader from '../../services/auth-header'
import { DatePicker } from 'antd';
import StatusActions from './statusActions';
const { RangePicker } = DatePicker;


// const data = [
//   {
//     key: '1',
//     name_caissier: 'John Brown ',
//     name_superviseur: 'Abou ndiaye',
//     date: '11-09-2022 à 17:00',
//     address: 'New York No. 1 Lake Park',
//     cni: '12543169870980999', 
//     ninea: '0001122 2G3',
//     email: 'ndiagua.bbs@gmail.com',

//   },
//   {
//     key: '2',
//     name_caissier: 'Joe Black',
//     name_superviseur: 'Ismaila ndiaye',
//     address: 'London No. 1 Lake Park',
//     date: '11-09-2022 à 17:20',
//     cni: '12543169870980999', 
//     ninea: '0001122 2G3',
//     email: 'ndiagua.bbs@gmail.com',

//   },
//   {
//     key: '3',
//     name_caissier: 'Jim Green',
//     name_superviseur: 'Sokhna Fall',
//     address: 'Sidney No. 1 Lake Park',
//     date: '11-09-2022 à 17:30',
//     cni: '12543169870980999', 
//     ninea: '0001122 2G3',
//     email: 'ndiagua.bbs@gmail.com',

//   },
//   {
//     key: '4',
//     name_caissier: 'Jim Red',
//     name_superviseur: 'Sokhna Fall',
//     address: 'London No. 2 Lake Park',
//     date: '11-09-2022 à 17:33',
//     cni: '12543169870980999', 
//     ninea: '0001122 2G3',
//     email: 'ndiagua.bbs@gmail.com',

//   },
//   {
//     key: '5',
//     name_caissier: 'Jim Red',
//     name_superviseur: 'Maman Fall',
//     address: 'London No. 2 Lake Park',
//     date: '11-09-2022 à 17:04',
//     cni: '12543169870980999', 
//     ninea: '0001122 2G3',
//     email: 'ndiagua.bbs@gmail.com',

//   },
//   {
//     key: '6',
//     name_caissier: 'Jim Red',
//     name_superviseur: 'Def ndiaye',
//     address: 'London No. 2 Lake Park',
//     date: '11-09-2022 à 17:00',
//     cni: '12543169870980999', 
//     ninea: '0001122 2G3',
//     email: 'ndiagua.bbs@gmail.com',
//   },
// ];
const List1 = (props) => {

  let locale = {
    emptyText: 'Pas de caissier',
  };


  const id = props?.record?.id
  const [dataAll, setDataAll] = useState("");
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  //const user = JSON.parse(localStorage.getItem('user'));
  //console.log("id >",id);
  useEffect(() => {
    setLoggedIn(false)
    getAll()
  }, []);
  const getAll = async () => {
    const data = JSON.stringify({
      "id": id
    });
    const config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + "/showCaissiers",
      headers: authHeader(),
      data: data
    };
    axios(config)
      .then(function (response) {
        setLoggedIn(true)
        // console.log("respose list :", response.data);
        setDataAll(response.data.response.user)
      })
      .catch(function (error) {
        // console.log(error.response.data);
      });
  }
  const [dates, setDates] = useState(null);
  const [values, setValues] = useState(null);
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
    //console.log('value >> '+ dates[0]+' dates >>'+ dates[0])
    return !!tooEarly || !!tooLate;
  };
  function convert(str) {
    var dates = new Date(str),
      mnth = ("0" + (dates.getMonth() + 1)).slice(-2),
      day = ("0" + dates.getDate()).slice(-2);
    return [dates.getFullYear(), mnth, day].join("-");
  }
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const columns = [
    {
      title: 'Caissiers',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => {
        return record?.username
        // return <a onClick={() => { infoClient(record) }}>{record?.name}</a>
      },
      sorter: (a, b) => a.username.length - b.username.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Addresse',
      dataIndex: 'adresse',
      key: 'adresse',
      render: (text, record) => {
        return record?.adresse
      },
      sorter: (a, b) => a.adresse.length - b.adresse.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Numero',
      dataIndex: 'phone',
      key: 'phone',
      render: (text, record) => {
        return record?.phone
      },
    },

    {
      title: 'Date',
      dataIndex: 'date_heure',
      key: 'date_heure',
      width: '25%',
      filters: [
        {
          text: <RangePicker
            value={dates || values}
            disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
            onChange={(val) => setValues(val)}
            onOpenChange={onOpenChange}

          />,
          // value: convert(values[0]._d),
          value: { values },
        },

      ],
      filteredValue: filteredInfo.date_heure || null,
      onFilter: (value, record) => record.date_heure.includes(value),
      sorter: (a, b) => a.date_heure.length - b.date_heure.length,
      sortOrder: sortedInfo.columnKey === 'date_heure' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        return moment(record?.createdAt).format("DD-MM-YYYY HH:mm:ss");
      },

    },
    // {
    //     title: 'CNI',
    //     dataIndex: 'cni',
    //     key: 'cni',
    //     render: (text, record) => {
    //         return record?.cni
    //     },
    //  },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'block',
      render: (text, record) => {
        return <StatusActions record={record} />;
      },
    },

  ];
  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      >
      </Space>
      <Table locale={locale} columns={columns} pagination={{ pageSize: 5 }} dataSource={dataAll} onChange={handleChange} loading={!isLoggedIn} />
    </>
  );
};
export default List1;