//import { CalendarTwoTone } from '@ant-design/icons';
import { Button, Space, Table, Input } from 'antd';
import moment from 'moment'
import axios from 'axios';
import StatusActions from './StatusActions';
import BankerActions from './BankerActions';
// import { Calendar } from 'react-calendar';
// import DatePicker from "react-datepicker";
// import Date from 'components/Calendar';
import { SearchOutlined } from '@ant-design/icons';
import React, { useRef, useState, useEffect } from "react";
import Highlighter from 'react-highlight-words';



// const data = [
//   {
//     key: '1',
//     caisse: 'Cash Pay',
//     address: 'London No. 1 Lake Park',
//     date_heure: '2022-11-01',
//     montant: '780.000 fcfa',
//   },
//   {
//     key: '2',
//     caisse: 'Cash Pay',
//     address: 'New York No. 1 Lake Park',
//     date_heure: '2022-11-29',
//     montant: '750.000 fcfa',
//   },


// ];
const ListDemande = ({ state }) => {

  let locale = {
    emptyText: 'Pas de superviseur PDV',
  };


  const [isOpenModalhomeModal, setModalhomeModal] = React.useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [dataAll, setDataAll] = useState("");

  useEffect(() => {
    setLoggedIn(false)
    getAll()
  }, []);

  const getAll = async () => {
    console.log("state.identifiant list all :", state.user.id);

    const data = JSON.stringify({
      "id": state.user.id
    });

    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/showSupClient`,
      // url: "http://localhost:4042/api/v1/showSupClient",
      headers: {
        'Authorization': `Bearer ${state.token}`,
        'Content-Type': 'application/json'
      },
      data: data

    };

    axios(config)
      .then(function (response) {
        setLoggedIn(true)
        console.log("respose list :", response.data);
        setDataAll(response.data.response.user)
      })
      .catch(function (error) {
        console.log(error.response.data);
      });

  }



  const [dates, setDates] = useState(null);
  const [values, setValues] = useState(null);


  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;

    //console.log('value >> '+ dates[0]+' dates >>'+ dates[0])
    return !!tooEarly || !!tooLate;
  };

  function convert(str) {
    var dates = new Date(str),
      mnth = ("0" + (dates.getMonth() + 1)).slice(-2),
      day = ("0" + dates.getDate()).slice(-2);
    return [dates.getFullYear(), mnth, day].join("-");
  }

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }

  };





  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});



  ///
  const handleChange = (pagination, filters, sorter) => {

    setFilteredInfo(filters);
    setSortedInfo(sorter);

  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('')
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button

            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890FF' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#FFC069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  ///


  const columns = [

    {

      title: 'Nom',
      dataIndex: 'username',
      key: 'username',
      width: '10%',
      ...getColumnSearchProps('username'),
    
    render: (text, record) => {
      return record?.username
      
  },

      // title: 'Nom',
      // dataIndex: 'username',
      // key: 'username',

      // ...getColumnSearchProps('username'),
      // render: (text, record) => {
      //   return record?.username
      //   // return <a onClick={() => { infoClient(record) }}>{record?.name}</a>
      // },

    },

    // {
    //     title: 'Adresse',
    //     dataIndex: 'adresse',
    //     key: 'adresse',
    //     width: '15%',
    //     render: (text, record) => {

    //         return record?.adresse
    //     },
    //     sorter: (a, b) => a.adresse.length - b.adresse.length,
    //     sortDirections: ['descend', 'ascend'],
    // },

    {

      title: 'Telephone',
      dataIndex: 'phone',
      key: 'phone',
      width: '15%',
      render: (text, record) => {

          return record?.phone
      },


      // title: 'Telephone',
      // dataIndex: 'phone',
      // key: 'phone',

      // render: (text, record) => {

      //   return record?.phone
      // },
    },

    {

      title: 'Adresse',
      dataIndex: 'adresse',
      key: 'adresse',
      width: '15%',
      render: (text, record) => {

          return record?.adresse
      },

      // title: 'Adresse',
      // dataIndex: 'adresse',
      // key: 'adresse',
      // render: (text, record) => {

      //   return record?.adresse
      // },

    },
    // {
    //     title: 'Status',
    //     dataIndex: 'status',
    //     key: 'status',
    //     render: (text, record) => {

    //         return record?.status
    //     },
    // },
    {

      title: 'Date',
      dataIndex: 'date_heure',
      key: 'date_heure',
      width: '25%',
      render: (text, record) => {
        return moment(record?.createdAt).format("DD-MM-YYYY HH:mm:ss");
         },

      // title: 'Date',
      // dataIndex: 'date_heure',
      // key: 'date_heure',
      // width: '60%',

      // render: (text, record) => {
      //   return moment(record?.createdAt).format("DD-MM-YYYY HH:mm:ss");
      // },

    },
    {

      title: 'Status',
      dataIndex: 'block',
      key: 'block',
      width: '18%',
     
      render: (text, record) => {
        return <StatusActions record={record} />
    },

      // title: 'Status',
      // dataIndex: 'transactions',
      // key: 'block',
      // width: '20%',

      // render: (text, record) => {
      //   return <StatusActions record={record} />
      // },

    },
    {

      title: 'Actions ',
      key: 'action',
      dataIndex: 'banker_approvement',
      width: '13%',
      render: (banker_approvement, record) => {
          return <BankerActions record={record} />
      },
      ellipsis: true,

      // title: 'Actions ',
      // key: 'action',
      // dataIndex: 'banker_approvement',
      // width: '50%',
      // render: (banker_approvement, record) => {
      //   return <BankerActions record={record} />
      // }
      
    },
  ];
  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      >


      </Space>
      <Table locale={locale} columns={columns} pagination={{ pageSize: 5 }} dataSource={dataAll} onChange={handleChange} loading={!isLoggedIn} />
    </>
  );
};
export default ListDemande;