import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/login`
// const API_URL = "https://www.services.bbsgroup.tech/cup/superviseur-commercial/login";

const register = (username, email, password) => {
  return axios.post(API_URL , {
    username,
    email,
    password,
  });
};

const login = async (username, password) => {
  return await axios
  .post(API_URL , {
    username,
    password,
  })
  .then((response) => {
    if (response.data.success === true) {
      localStorage.setItem("u", JSON.stringify(response.data));
    }

    return response.data;
  });
};


 
const verifyLoginOtp = (userId,otp)=>{
  // localhost:4041/api/v1/login/verify/loginOtp
  return axios
  .post(API_URL + "/verify/loginOtp", {
   userId,
   otp,
 })
 .then((response) => {
   if (response.data.success === true) {
     localStorage.setItem("user", JSON.stringify(response.data));
   }

   return response.data;
 });

}

 

const logout = () => {
  localStorage.removeItem("u");
  localStorage.removeItem("user");
};

export default {
  register,
  login,
  logout,
  verifyLoginOtp 
};