import axios from "axios";
import authHeader from "./auth-header";
const API_URL = `${process.env.REACT_APP_API_URL}/updateSuperviseur`;
// const API_URL = "http://localhost:4042/api/v1/updateSuperviseur";

//update caissier

const updatesuperviseur = (id,firstName,lastName,username,phone,CNI,email,adresse,created_by) => {
  const data = JSON.stringify({
     "id": id,
    "firstName": firstName,
    "lastName": lastName,
    "username": username,
    "phone": phone,
    "CNI": CNI,
    "email": email,
    "adresse": adresse,
    "created_by":created_by
  });
  const config = {
    method: 'put',
    url: API_URL ,
    headers: authHeader(),
    data : data
  };
  return axios(config)
};

// eslint-disable-next-line import/no-anonymous-default-export

export default {
  updatesuperviseur
}