import { CalendarTwoTone } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import React, { useState,useEffect} from 'react';
import ReapService from '../../services/reap-service';
import moment from 'moment'
import axios from 'axios';
import StatusActions from './StatusActions';
// import { Calendar } from 'react-calendar';
// import DatePicker from "react-datepicker";
// import Date from 'components/Calendar';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

// const data = [
//   {
//     key: '1',
//     caisse: 'Cash Pay',
//     address: 'London No. 1 Lake Park',
//     date_heure: '2022-11-01',
//     montant: '780.000 fcfa',
//   },
//   {
//     key: '2',
//     caisse: 'Cash Pay',
//     address: 'New York No. 1 Lake Park',
//     date_heure: '2022-11-29',
//     montant: '750.000 fcfa',
//   },
  
   
// ];
const ListReapp = ({state}) => {
  let locale = {
    emptyText: 'Pas de reapprovisionnement',
  }; 

  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [dataAll, setDataAll] = useState("");
  
 

  const [content, setContent] = useState("");
  useEffect(() => {
    setLoggedIn(false)
    ReapService.getAllReap().then(
      (response) => {
        setLoggedIn(true)
        setContent(response.data.response.reapprovisionnement);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

    // const getAll = async () => {
    //   console.log("state.identifiant list all :",state.identifiant);
       
     
      
    //   const config = {
    //     method:'get',
    //     url: "http://localhost:4041/api/v1/getAll",
    //     headers: { 
    //       'Authorization': `Bearer ${state.token}`, 
    //       'Content-Type': 'application/json'
    //     }
        
    //   };
      
    //   axios(config)
    //   .then(function (response) {
    //     console.log("operations :", response.data);
    //     setDataAll(response.data.response.reapprovisionnement)
    //   })
    //   .catch(function (error) {
    //     console.log(error.response.data);
    //   });
      
    // }
   
  console.log("getAl >",content);

  const [dates, setDates] = useState(null);
  const [values, setValues] = useState(null);
 
    
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
    
    //console.log('value >> '+ dates[0]+' dates >>'+ dates[0])
    return !!tooEarly || !!tooLate;
  };

  function convert(str) {
    var dates = new Date(str),
      mnth = ("0" + (dates.getMonth() + 1)).slice(-2),
      day = ("0" + dates.getDate()).slice(-2);
    return [dates.getFullYear(), mnth, day].join("-");
  }

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
    
  };





  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'heure',
    });
  };
  const columns = [
    {
      title: 'Nom',
      dataIndex: 'caisse',
      key: 'caisse',
      filters: [
        {
          text: 'Cash',
          value: 'Cash',
        },
        
      ],
      filteredValue: filteredInfo.caisse || null,
      onFilter: (value, record) => record.caisse.includes(value),
      sorter: (a, b) => a.caisse.length - b.caisse.length,
      sortOrder: sortedInfo.columnKey === 'caisse' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        return record?.user
         },
    },
   
    {
      title: 'Montant(FCFA)',
      dataIndex: 'montant',
      key: 'montant',
      sorter: (a, b) => a.montant.length - b.montant.length,
      sortOrder: sortedInfo.columnKey === 'montant' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        return record?.amount
         },
  },

   
    {
      title: 'Date',
      dataIndex: 'date_heure',
      key: 'date_heure',
      filters: [
        {
          text:  <RangePicker
          value={dates || values}
          disabledDate={disabledDate}
          onCalendarChange={(val) => setDates(val)}
          onChange={(val) => setValues(val)}
          onOpenChange={onOpenChange}
           
        />,
          // value: convert(values[0]._d),
          value: {values},
        },
        
      ],
      filteredValue: filteredInfo.date_heure|| null,
      onFilter: (value, record) => record.date_heure.includes(value),
      sorter: (a, b) => a.date_heure.length - b.date_heure.length,
      sortOrder: sortedInfo.columnKey === 'date_heure' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        return moment(record?.createdAt).format("DD-MM-YYYY HH:mm:ss");
         },
      
    },
    
     
   
    {
      title: 'Status',
      dataIndex: 'transactions',
      key: 'status',
     
      render: (text, record) => {
        return <StatusActions record={record} />
    },
      filters: [
        {
          text: 'PENDING',
          value: 'PENDING',
        },
        {
          text: 'ECHEC',
          value: 'ECHEC',
        },
        {
          text: 'SUCCESS',
          value: 'SUCCESS',
        }
      ],
      filteredValue: filteredInfo.status || null,
        onFilter: (value, record) => record.status.includes(value),
      sorter: (a, b) => a.type.length - b.type.length,
    sortOrder: sortedInfo.columnKey === 'montant' ? sortedInfo.order : null,
    ellipsis: true,
     
  },
  ];
  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      >
        
        <Button onClick={clearFilters}>Effacer les filtres</Button>
        <Button onClick={clearAll}>Effacer les filtres et les trieurs</Button>
      </Space>
      <Table locale={locale} columns={columns} pagination={{ pageSize: 4}} dataSource={content} onChange={handleChange} loading ={!isLoggedIn} />
    </>
  );
};
export default ListReapp;