import {
  VALIDE_SUCCESS,
  VALIDE_FAIL,
  SET_MESSAGE,
} from "./types";

import AppelService from "../services/appel-service";

export const create = (montant, user, senderId, receiverId) => (dispatch) => {
  return AppelService.create(montant, user, senderId, receiverId).then(
    (data) => {
      dispatch({
        type: VALIDE_SUCCESS,
        payload: { data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: VALIDE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
