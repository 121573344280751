import {
  VALIDE_SUCCESS,
  VALIDE_FAIL,
  SET_MESSAGE,
} from "./types";
import SuperviseurService from "../services/create-superviseur";
export const superviseur = (firstName, lastName, username, adresse, phone, CNI, email, created_by, img1, img2) => (dispatch) => {
  return SuperviseurService.superviseur(firstName, lastName, username, adresse, phone, CNI, email, created_by, img1, img2).then(
    (response) => {
      dispatch({
        type: VALIDE_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: VALIDE_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};