import React from "react";
import Ajoutsuperviseur from "pages/Ajoutsuperviseur";
import Listesuperviseursmaster from "pages/Listesuperviseursmaster";
import Caissiers from "pages/Superviseurs";
import Deposits from "pages/Caissiers";
import ModifyCaissier from "pages/ModifySuperviseur";
import AjoutCaissier from "pages/AjoutCaissier";
import DashboardSuperviseur from "pages/DashboardSuperviseur";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Reclamations from "pages/Réclamations";
import Login from "pages/Login";
import ProfilePage from "pages/Profile";
import ReapprovisionnementPage from "pages/Reapprovisionnement";
import AppelFonPage from "pages/AppelFond";


const ProjectRoutes = () => {
  return (
    <Router>
      <Routes>

         <Route path="*" element={<NotFound />} />
         <Route path="/dashboardsuperviseur" element={<DashboardSuperviseur />} />
         <Route path="/ajoutcaissier" element={<AjoutCaissier />} />
         <Route path="/modifycaissier" element={<ModifyCaissier />} />
         <Route path="/superviseurs" element={<Caissiers />} />
         <Route path="/caissiers" element={< Deposits />} />
         <Route path="/appels" element={< Reclamations />} />    
         <Route path="/" element={<Login />} />
         <Route path="/profile" element={<ProfilePage />} />
         <Route path="/reapp" element={<ReapprovisionnementPage />} />
         <Route path="/appel" element={<AppelFonPage />} />
         <Route path="/listesuperviseursmaster" element={<Listesuperviseursmaster />}/>
         <Route path="/ajoutsuperviseur" element={<Ajoutsuperviseur />} />
                
      </Routes>
    </Router>
  );
};

export default ProjectRoutes;

