import React, { useState,useCallback } from "react";
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Button,
} from "@chakra-ui/react";
import OTPInput from "otp-input-react";
import { Img,Row } from "../../../components";
import { FormControl, FormLabel, Grid, Input, Select,InputGroup,InputRightElement } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";

import { updateProfile,updatePassword } from "../../../actions/user";
import { clearMessage } from "../../../actions/message";
import {logout} from "../../../actions/auth"
import { Modal } from "antd";
import { useNavigate  } from 'react-router-dom';
import { verifyPhoneOtp } from "../../../actions/user";
import {EyeOutlined} from '@ant-design/icons';




// import AccountSettings from './AccountSettings'
//import Actions from './Actions'


const Content = () => {
  const tabs = ["Paramètres du compte", "Changer mot de passe"];

  const { user: currentUser } = useSelector((state) => state.auth);
  const [successful, setSuccessful] = useState(false);
  const [show, setShow] = React.useState(false)
  const  [otp, setOtp]  = React.useState ("")
  const [show1, setShow1] = React.useState(false)
  const [show2, setShow2] = React.useState(false)
  const handleClick = () => setShow(!show)
  const handleClick2 = () => setShow2(!show2)
  const handleClick1 = () => setShow1(!show1)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const userId = currentUser.response.user.id;
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const user = currentUser.response.user;
  const firstname = user.firstName;
  const lastname = user.lastName;
  const Email = user.email;
  const Phone = user.phone;
  const  [password, setPassword]  = React.useState ( "" );
  const  [newpassword, setNewpassword]  = React.useState ( "" );
  const  [comfirmpassword, setComfirmpassword]  = React.useState ( "" );

  let navigate = useNavigate();
  const [firstName, setfirstName] = React.useState({
    value: firstname,

    setValue: (newValue) => {
      setfirstName({ ...firstName, value: newValue });
    },
  });
  const [lastName, setlastName] = React.useState({
    value: lastname,

    setValue: (newValue) => {
      setlastName({ ...lastName, value: newValue });
    },
  });
  const [email, setEmail] = React.useState({
    value: Email,

    setValue: (newValue) => {
      setEmail({ ...email, value: newValue });
      console.log(email.value);
    },
  });

  

  const [phone, setPhone] = React.useState({
    value: Phone,

    setValue: (newValue) => {
      setPhone({ ...phone, value: newValue });
    },
  });

  const onChangefirstName = (e) => {
    const firstName = e.target.value;
    setfirstName(firstName);
  };

  const onChangelastName = (e) => {
    const lastName = e.target.value;
    setlastName(lastName);
  };

  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSuccessful(true);

    dispatch(
      updateProfile(
        userId,
        firstName.value || firstName,
        lastName.value || lastName,
        email.value || email,
        phone.value || phone
      )
    )
      .then(() => {
        setSuccessful(false);
        setIsModalOpen(true);

        //navigate("/om");
      })
      .catch(() => {
        setSuccessful(true);
        setIsModalOpen(true);
      });
  };

  

  
  const handleOk = () => {
    setIsModalOpen(false);
    setSuccessful(false);
    dispatch(clearMessage()); // clear message when changing location
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(clearMessage()); // clear message when changing location
  };
  const logou = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);


  const handleOk1 = () => {

    logou()
    setIsModalOpen1(false);
    setSuccessful(false);
    dispatch(clearMessage()); // clear message when changing location
    navigate('/')
  };

  const handleCancel1 = () => {
    setIsModalOpen(false);
    dispatch(clearMessage()); // clear message when changing location
  };

  const  handleSubmit3 =  (e) => {
    e.preventDefault();
    setSuccessful(true);
    if(newpassword === comfirmpassword ){
    
    dispatch(verifyPhoneOtp(userId, otp))
      .then(() => {
       
        setIsModalOpen3(false);
       
        setSuccessful(true);

      dispatch(
        updatePassword(
          userId,
          password,
          newpassword
        )
      )
        .then(() => {
          setIsModalOpen1(true);
 
        })
        .catch(() => {
          setSuccessful(true);
          setIsModalOpen(true);
        });
    
        
      })
      .catch(() => {
        setSuccessful(false);
        setOtp("")
        
      });

    }else{
      setSuccessful(false);
    }
  
  };
  const handleShow = ()=>{
    if(newpassword ==="" && comfirmpassword==="" && password===""){
      return null;
     }else{

      if(newpassword === comfirmpassword )
      {
        setIsModalOpen3(true);
      }
 
    
  }
  }
  const handleOk3 = () => {
    setIsModalOpen3(false);
   
    dispatch(clearMessage()); // clear message when changing location
    
  };

  const handleCancel3 = () => {
    setIsModalOpen3(false);
   
    dispatch(clearMessage()); // clear message when changing location
    
  };
  return (
    <>
      <Box
        as="main"
        flex={3}
        d="flex"
        flexDir="column"
        justifyContent="space-between"
        pt={5}
        bg="white"
        rounded="md"
        borderWidth={1}
        borderColor="gray.200"
        style={{ transform: "translateY(-100px)" }}
      >
        <Tabs>
          <TabList px={5}>
            {tabs.map((tab) => (
              <Tab
                key={tab}
                mx={3}
                px={0}
                py={3}
                fontWeight="semibold"
                color="brand.cadet"
                borderBottomWidth={1}
                _active={{ bg: "transparent" }}
                _selected={{ color: "brand.dark", borderColor: "brand.blue" }}
              >
                {tab}
              </Tab>
            ))}
          </TabList>

          <TabPanels px={3} mt={5}>
            <TabPanel>
              {/* <AccountSettings /> */}
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                }}
                gap={6}
              >
                <FormControl id="firstName">
                  <FormLabel>Prénom</FormLabel>
                  <Input
                    focusBorderColor="brand.blue"
                    type="text"
                    value={firstName.value}
                    onChange={onChangefirstName}
                    disabled
                  />
                </FormControl>
                <FormControl id="lastName">
                  <FormLabel>Nom de famille</FormLabel>
                  <Input
                    focusBorderColor="brand.blue"
                    type="text"
                    value={lastName.value}
                    onChange={onChangelastName}
                    disabled
                  />
                </FormControl>
                <FormControl id="phoneNumber">
                  <FormLabel>Numéro de téléphone</FormLabel>
                  <Input
                    focusBorderColor="brand.blue"
                    type="tel"
                    value={phone.value}
                    onChange={onChangePhone}
                    disabled
                  />
                </FormControl>
                <FormControl id="emailAddress">
                  <FormLabel>Adresse e-mail</FormLabel>
                  <Input
                    focusBorderColor="brand.blue"
                    type="email"
                    value={email.value}
                    onChange={onChangeEmail}
                    disabled
                  />
                </FormControl>
                <FormControl id="city">
                  <FormLabel>Ville</FormLabel>
                  <Select
                    focusBorderColor="brand.blue"
                    placeholder="Select city"
                  >
                    <option value="Dakar" selected>
                      Dakar
                    </option>
                    <option value="washington"></option>
                    <option value="toronto"></option>
                  </Select>
                </FormControl>
                <FormControl id="country">
                  <FormLabel>Pays</FormLabel>
                  <Select
                    focusBorderColor="brand.blue"
                    placeholder="Select country"
                  >
                    <option value="senegal" selected>
                      Senegal
                    </option>
                    <option value="england"></option>
                    <option value="poland"></option>
                  </Select>
                </FormControl>
              </Grid>
              <Box
                mt={5}
                py={5}
                px={8}
                borderTopWidth={1}
                borderColor="brand.light"
              >
               
                {/* <Button onClick={handleSubmit} disabled={successful}>
                  {successful && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}{" "}
                  Mise à jour
                </Button> */}
              </Box>
            </TabPanel>
            <TabPanel>
            
            <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                }}
                gap={6}
              >
                <FormControl >
                  <FormLabel>Mot de Passe actuel</FormLabel>
                  <InputGroup size='md'>
                  <Input
                    pr='4.5rem'
                    type={show ? 'text' : 'password'}
                    placeholder='Entrer votre mot de passe'
                    value={password}
                    onChange = { e  => setPassword ( e.target.value)}
                   
                  />
                  <InputRightElement width='4.5rem'>
                    <button h='1.75rem' size='sm' onClick={handleClick}>
                    <EyeOutlined />
                    </button>
                  </InputRightElement>
                </InputGroup>
                  
                </FormControl>
                <FormControl >
                  
                </FormControl>
                <FormControl >
                  <FormLabel>Nouveau Mot de Passe</FormLabel>
                  <InputGroup size='md'>
                  <Input
                    pr='4.5rem'
                    type={show1 ? 'text' : 'password'}
                    placeholder='Entrer votre nouveau mot de passe'
                    value={newpassword}
                    onChange = { e  => setNewpassword ( e.target.value)}
                   
                  />
                  <InputRightElement width='4.5rem'>
                    <button h='1.75rem' size='sm' onClick={handleClick1}>
                    <EyeOutlined />
                    </button>
                  </InputRightElement>
                </InputGroup>
                  
                </FormControl>
                <FormControl >
                  <FormLabel>Confirmer Mot de Passe</FormLabel>
                  <InputGroup size='md'>
                  <Input
                    pr='4.5rem'
                    type={show2 ? 'text' : 'password'}
                    placeholder='Confirmer votre mot de passe'
                    value={comfirmpassword}
                    onChange = { e  => setComfirmpassword ( e.target.value)}
                   
                  />
                  <InputRightElement width='4.5rem'>
                    <button h='1.75rem' size='sm' onClick={handleClick2}>
                    <EyeOutlined />
                    </button>
                  </InputRightElement>
                </InputGroup>
                </FormControl>
                </Grid>
            <Box
                mt={5}
                py={5}
                px={8}
                borderTopWidth={1}
                borderColor="brand.light"
              >
                <Button onClick={handleShow} >
                 
                  Mise à jour
                </Button>
            </Box>
          </TabPanel>
          </TabPanels>
        </Tabs>

        {/* <Actions /> */}
      </Box>
      <Modal
        title="Notification"
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        okText="Confirmer"
        cancelText="Annuler"
        onCancel={handleCancel}
      >
        {message && (
          <div className="form-group">
            <div
              className={
                successful ? "alert alert-danger" : "alert alert-success"
              }
              role="alert"
            >
              {message}
            </div>
          </div>
        )}
      </Modal>

      <Modal
        title="Notification"
        footer={null}
        open={isModalOpen1}
        onOk={handleOk1}
        okText="Confirmer"
        cancelText="Annuler"
        onCancel={handleCancel1}
      >
        {message && (
          <div className="form-group">
            <div
              className={
                successful ? "alert alert-success" : "alert alert-danger"
              }
              role="alert"
            >
              {message}
            </div>
          </div>
        )}
        
        

        <Button
                  className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[10px] 3xl:text-[18px] text-center w-[40%]"
                  shape="RoundedBorder8"
                  onClick={handleOk1}
                  >
                 
                  Fermer
                </Button>
      </Modal>

      <Modal  title="" footer={null} open={isModalOpen3} onOk={handleOk3} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel3}>

      {message && (
            <div className="form-group">
              <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                {message}
              </div>
            </div>
          )} 
          
          <div class=" p-2 text-center"> 
          <Img 
              src="images/coolupay.png"
              className="lg:h-[70px] xl:h-[80px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[70%]"
              alt="logobbsOne"
            />
                  {/* <h4>Please enter the one time password <br/> to verify your account</h4> */}
                  <h4>Veuillez entrer code OTP à usage unique <br/> pour vérifier votre compte</h4>
                  
                  
                  <br/>
                  <div className="absolute content-center items-center  right-[-10] top-[20] "> 
                <OTPInput   
                  value={otp} 
                  inputStyles={{width: "20%",
                  height: "30px",
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: "bold"}}
                    onChange={setOtp} 
                    inputStyle ="text-center"
                    autoFocus 
                    OTPLength={4} 
                    otpType="number" disabled={false}  />
                  
                </div>
                </div>
                    {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
                <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
                  <Button
                    className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                    
                    shape="RoundedBorder8"
                    
                    variant="FillBluegray100"
                    onClick={handleOk}
                  >
                    Annuler
                  </Button>

                  <Button
                    
                    className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                    shape="RoundedBorder8"
                    onClick={handleSubmit3}
                    
                    disabled={successful}>
                    {successful && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    Confirmer
                  </Button>
                </Row>
              
    
  
       </Modal> 
    </>
  );
};

export default Content;