import ProfileComponent from "components/Profile";
import React from "react";

const ProfilePage = () => {
  
  return (
    <>
      <ProfileComponent/>
    </>
  );
};

export default ProfilePage;
