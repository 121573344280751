import { Space } from 'antd';
import { Button } from 'components/Button';
import React, { useState } from 'react';
import { Alert } from 'antd';
export default function StatusActions(props) {
    const blocked = props?.record?.block
    const [loading] = useState(false)
    if (loading) return <Button type="primary" shape="circle" loading />
    if (blocked === false) return (
        <Alert style={{ border: 0 }} message="ACTIF" type="success" showIcon />
    )
    if (blocked === true) return (
        <Alert style={{ border: 0 }} message="BLOQUE" type="error" showIcon />
    )
    return (
        <Space size='middle' >
        </Space>
    )
}