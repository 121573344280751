import axios from "axios";
import authHeader from "./auth-header";
const API_URL = `${process.env.REACT_APP_API_URL}/deblocked`
// const API_URL = "http://localhost:4042/api/v1/deblocked";

//update solde
const deblocked = (id,userId) => {
  const data = JSON.stringify({
    "id": id,
    "userId": userId
  });
  const config = {
    method: 'put',
    url: API_URL ,
    headers: authHeader(),
    data : data
  };
  var resp = axios(config)
  console.log("respnse block :",resp);

  return resp
  return axios(config)
};

// eslint-disable-next-line import/no-anonymous-default-export

export default {
  deblocked
}