import axios from "axios";
import authHeader from "./auth-header";

// const API_URL = "http://localhost:4042/api/v1";
const API_URL = process.env.REACT_APP_API_URL;

const getBalance = () => {
  //return axios.get(API_URL + "user/balance",{id});
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "id": userId,
  });

  const config = {
    method: 'post',
    url: API_URL + "/balance",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
};

const getAllTransation = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  //return axios.get(API_URL + "user/balance",{id});
  const data = JSON.stringify({
    "userId": userId,
  });
  

  const config = {
    method: 'post',
    url: API_URL + "transaction/show",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
};

const generateOtp  = ()=>{

  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  
  const data = JSON.stringify({
    "userId": userId,
  });
  

  const config = {
    method: 'post',
    url: API_URL + "/generateOtp",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
}

const verifyPhoneOtp = (userId,otp)=>{

  var data = JSON.stringify({
    "userId": userId,
    "otp": otp
  });
  
  var config = {
    method: 'post',
    url: API_URL+'/verifyPhoneOtp',
    headers:authHeader(),
    data : data
  };
  
   return axios(config)

}

const countSuperviseur = () => {
  //return axios.get(API_URL + "user/balance",{id});
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "userId": userId,
  });
  

  const config = {
    method: 'post',
    url: API_URL+'/countSuperviseur',
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
};

///update profile
const updateProfile = (userId,firstName,lastName,email,phone)=>{
  var data = JSON.stringify({
    "userId": userId,
    "firstName": firstName,
    "lastName": lastName,
    "email":email,
    "phone":phone
  });
  const config = {
    method: 'put',
    // url: API_URL + "user/profile/update",
    url: API_URL+'/updateProfile',
    // url : "http://localhost:4042/api/v1/updateProfile",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)

}

///update password

const updatePassword = (userId,password,newPassword)=>{
  var data = JSON.stringify({
    "userId": userId,
    "password": password,
    "newPassword": newPassword
  });
  const config = {
    method: 'put',
    url: API_URL+'/updatePassword',
    // url:  "http://localhost:4042/api/v1/updatePassword",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)

}

const count = () => {
  //return axios.get(API_URL + "user/balance",{id});
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "id": userId,
  });
  

  const config = {
    method: 'post',
    url: API_URL+'/countAll',
    // url: "http://localhost:4042/api/v1/countAll",
    headers: authHeader(),
      
    data : data
  };
  return axios(config)
};




// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getBalance,getAllTransation,updateProfile,updatePassword,
    generateOtp,verifyPhoneOtp,countSuperviseur,count
  };