import { Space, Modal } from 'antd';
import React, { useState } from 'react';
// import { confirmerCommande, setBankerLRApprovement } from '../../../services/banker.service';
import { EditTwoTone, DeleteTwoTone, EyeTwoTone, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { IoInformation } from 'react-icons/io5'
import { verifyPhoneOtp } from "../../actions/user";
import { useNavigate } from "react-router-dom";
import { blocked } from "../../actions/blocked";
import { deblocked } from '../../actions/deblocked';
import OTPInput, { ResendOTP } from "otp-input-react";
import StatusActions from './StatusActions';
import { Row, Text, Img, Column, Button } from "components";
import { FcInfo, FcPrint } from "react-icons/fc";



// // ici on a popup qui affiche tous les details et fichiers joints par le client


export default function BankerActions(props) {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [successful, setSuccessful] = useState(false);
  const dispatch = useDispatch();
  const state = props?.record


  ///
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [montant, setMontant] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen0, setIsModalOpen0] = useState(false);
  // const navigate = useNavigate();



  // const [successful, setSuccessful] = useState(false);
  const response = JSON.parse(localStorage.getItem('user'));
  // const userId = response.response.user.id

  const { message } = useSelector(state => state.message);
  // const dispatch = useDispatch();

  const [content, setContent] = useState("");

  ///

  const generate = () => {

    setIsModalOpen(true);

  };




  const userId = currentUser.response.user.id
  const id = state.id;


  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccessful(true);
    dispatch(verifyPhoneOtp(userId, otp))
      .then(() => {
        // setSuccessful(true);
        // navigate("/caissiers");
        // window.location.reload();
        setIsModalOpen(false);
        setIsModalOpen2(true);
        //  console.log("id and userId in handleSubmit",state.id,state.userId);
        dispatch(blocked(id, userId))
        // navigate("/dashboardsuperviseur");


      })

      .catch(() => {
        setSuccessful(false);

      });



  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    setSuccessful(true);
    dispatch(verifyPhoneOtp(userId, otp))
      .then(() => {
        setSuccessful(true);
        // navigate("/caissiers");
        // window.location.reload();
        setIsModalOpen(false);
        setIsModalOpen3(true);
        //  console.log("id and userId in handleSubmit",state.id,state.userId);
        dispatch(deblocked(id, userId))
        // navigate("/dashboardsuperviseur");

      })

      .catch(() => {
        setSuccessful(false);

      });



  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk0 = () => {
    setIsModalOpen0(false);
  };

  const handleCancel0 = () => {
    setIsModalOpen0(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const handleOk3 = () => {
    setIsModalOpen3(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };

  const handleSubmit10 = () => {
    window.location.reload();
  };



  const navigate = useNavigate();
  function updateCaissier(record) {
    navigate(
      '/modifycaissier',
      {
        state: {
          id: record?.id,
          firstName: record?.firstName,
          lastName: record?.lastName,
          username: record?.username,
          email: record?.email,
          adresse: record?.adresse,
          phone: record?.phone,
          cni: record?.cni
        }
      }
    )


  }
  function deleteCaissier(record) {
    navigate(
      '/deletecaissier',
      {
        state: {
          name: record?.key,

        }
      }
    )
  }

  function showInfosCaissier() {

    setIsModalOpen0(true);
    // Modal.info({
    //   title: 'Informations sur le Superviseur',
    //   content: (
    //     <p style={{  margin: auto; }}>
    //       <p>Id: {record.id}</p>
    //       <p>Username :  {record.username}</p>
    //       <p>Adresse :  {record.adresse}</p>
    //       <p>Telephone: {record.phone}</p>
    //       <p>CNI: {record.cni}</p>
    //       <p>EMAIL: {record.email}</p>
    //       <p>Statut:<StatusActions record={record} /></p>

    //       <br />

    //     </p>
    //   )

    // });
  }

  function liste(record) {
    navigate(
      '/caissiers',
      {
        state: { id: record?.id }
      }
    )
  }
  const [approvement, setApprovement] = useState(props?.record?.banker_approvement?.approvement)
  const [loading, setLoading] = useState(false)
  const [sold, setSold] = useState(props?.record?.deed_of_sale?.sold)
  const [commandeConfirmed, setCommandeConfirmed] = useState(props?.record?.order?.confirmed)
  console.log('confirmed : ', commandeConfirmed)
  const block = props?.record?.block

  if (loading) return <Button type="primary" shape="circle" loading />
  if (block === true) return (
    <Space size="small">

      <button onClick={() => { showInfosCaissier() }}><IoInformation color="#1890ff" size="25px" title="voir les informations du superviseur PDV" /></button>
      <Modal   title={<div style={{  display:"flex" }}><FcInfo size="25px" /><p>Information du superviseur</p></div>}  footer={null} open={isModalOpen0} onOk={handleOk0} closable={false}>
      <div>
        <div id="divToPrint1" style={{
          marginTop: 10, backgroundColor: '#f5f5f5'
        }}>
     
        <p>Id: <b>{props?.record.id}</b></p>
        <p>Npm d'utilisateur :  <b>{props?.record.username}</b></p>
        <p>Adresse :  <b>{props?.record.adresse}</b></p>
        <p>Telephone: <b>{props?.record.phone}</b></p>
        <p>CNI: <b>{props?.record.cni}</b></p>
        <p>EMAIL: <b>{props?.record.email}</b></p>
        <p>Statut:<b><StatusActions record={props?.record} /></b></p>

      </div>

      </div>

      <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[100%]">
         <div   style={{width:":85%"}} ></div>
         
          <Button

  
            onClick={handleOk0}
            disabled={successful}>
            {successful && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            OK
          </Button>
        </Row>

    </Modal>
      {/* <Button icon={<EditTwoTone twoToneColor="#52C41A" title="Update " />} type="text" onClick={() => { updateCaissier(props?.record) }} /> */}
      <button onClick={generate}>< CheckCircleTwoTone twoToneColor="#52C41A" title="debloquer le superviseur PDV" /></button>
      <Modal title="verification code OTP" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel} closable={false}>

        {message && (
          <div className="form-group">
            <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
              {message}
            </div>
          </div>
        )}


        <div class=" p-2 text-center">
          <Img
            src="images/coolupay.png"
            className="lg:h-[80px] xl:h-[80px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[80%]"
            alt="logobbsOne"
          />
          <h4>Veuillez entrer votre code OTP</h4>


          <br />
          <div className="absolute content-center items-center  right-[-10] top-[20] ">
            <OTPInput
              inputStyles={{
                width: "20%",
                height: "30px",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "bold"
              }}
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={4}
              otpType="number" disabled={false} />
            <div></div>
          </div>
        </div>
        <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
          <Button
            className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"

            shape="RoundedBorder8"

            variant="FillBluegray100"
            onClick={handleOk}
          >
            Annuler
          </Button>
          <Button

            className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
            shape="RoundedBorder8"
            onClick={handleSubmit1}

            disabled={successful}>
            {successful && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            Confirmer
          </Button>
        </Row>
      </Modal>
      <Modal footer={null} open={isModalOpen3} onOk={handleOk3} onCancel={handleSubmit10} closable={false}>
        <div className="m-[auto] max-h-[97vh] items-center  w-[-100%]">
          <Column className="bg-gray_102 items-center justify-start lg:p-[26px] xl:p-[32px] 2xl:p-[36px] 3xl:p-[439px] rounded-radius16 w-[100%]">



            <Img
              src="images/img_close.svg"
              className="common-pointer test_1200x630waTwo"
              onClick={handleSubmit10}
              alt="close"
            />
            <Text className="columnclose2 alert alert-success" as="h8" variant="h8">
              le superviseur est debloque
            </Text>
            <Img
              src="images/valide.png"
              className="sandclockOne"
              alt="sandclockOne"
              width="35%"
            />
            {/* <Text className="columnclose2 alert alert-danger" as="h4" variant="h4">
                           {props.message} 
                         
                     </Text> */}


          </Column>

        </div>

      </Modal>
       

     

    </Space>
  )
  return (
    <Space size="small">
      <button onClick={() => { showInfosCaissier() }}><IoInformation color="#1890ff" size="25px" title="voir les informations du superviseur PDV" /></button>
      <Modal  title={<div style={{  display:"flex" }}><FcInfo size="25px" /><p>Information du superviseur</p></div>}  footer={null} open={isModalOpen0} onOk={handleOk0} closable={false}>
      <div>
        <div id="divToPrint1" style={{
          marginTop: 10, backgroundColor: '#f5f5f5'
        }}>
         
        {/* <p style={{  margin: "auto" }}> */}
        <p>Id: <b>{props?.record.id}</b></p>
        <p>Npm d'utilisateur :  <b>{props?.record.username}</b></p>
        <p>Adresse :  <b>{props?.record.adresse}</b></p>
        <p>Telephone: <b>{props?.record.phone}</b></p>
        <p>CNI: <b>{props?.record.cni}</b></p>
        <p>EMAIL: <b>{props?.record.email}</b></p>
        <p>Statut: <b><StatusActions record={props?.record} /></b></p>
        {/* </p> */}

      </div>

      </div>

      <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[100%]">
         <div   style={{width:":85%"}} ></div>
         
          <Button

  
            onClick={handleOk0}
            disabled={successful}>
            {successful && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            OK
          </Button>
        </Row>

    </Modal>
      {/* <Button icon={<EditTwoTone twoToneColor="#52C41A" title="Update " />} type="text" onClick={() => { updateCaissier(props?.record) }} /> */}
      <button onClick={generate}><CloseCircleTwoTone twoToneColor="#EB2F96" title="bloquer le superviseur PDV" /></button>
      {/* <Button onClick={generate} icon={<DeleteTwoTone  twoToneColor="#EB2F96" title="Rejeter demande" />} type="text"  /> */}
      <Modal title="verification code OTP" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel} closable={false}>

        {message && (
          <div className="form-group">
            <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
              {message}
            </div>
          </div>
        )}


        <div class=" p-2 text-center">
          <Img
            src="images/coolupay.png"
            className="lg:h-[80px] xl:h-[80px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[80%]"
            alt="logobbsOne"
          />
          <h4>Veuillez entrer votre code OTP</h4>


          <br />
          <div className="absolute content-center items-center  right-[-10] top-[20] ">
            <OTPInput
              inputStyles={{
                width: "20%",
                height: "30px",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "bold"
              }}
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={4}
              otpType="number" disabled={false} />
            <div></div>
          </div>
        </div>
        <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
          <Button
            className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"

            shape="RoundedBorder8"

            variant="FillBluegray100"
            onClick={handleOk}
          >
            Annuler
          </Button>
          <Button

            className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
            shape="RoundedBorder8"
            onClick={handleSubmit}

            disabled={successful}>
            {successful && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            Confirmer
          </Button>
        </Row>
      </Modal>
      <Modal footer={null} open={isModalOpen2} onOk={handleOk2} onCancel={handleSubmit10} closable={false}>
        <div className="m-[auto] max-h-[97vh] items-center  w-[-100%]">
          <Column className="bg-gray_102 items-center justify-start lg:p-[26px] xl:p-[32px] 2xl:p-[36px] 3xl:p-[439px] rounded-radius16 w-[100%]">



            <Img
              src="images/img_close.svg"
              className="common-pointer test_1200x630waTwo"
              onClick={handleSubmit10}
              alt="close"
            />
            <Text className="columnclose2 alert alert-danger" as="h8" variant="h8">
              le superviseur est bloque
            </Text>
            <Img
              src="images/invalid.webp"
              className="sandclockOne"
              alt="sandclockOne"
              width="35%"
            />
            {/* <Text className="columnclose2 alert alert-danger" as="h4" variant="h4">
                           {props.message} 
                         
                     </Text> */}


          </Column>

        </div>

      </Modal>

  
      <button onClick={() => { liste(props?.record) }}><EyeTwoTone twoToneColor="#52C41A" title="voir ces caissiers" /></button>
       
    </Space>
  )


}


{/* <Button  icon={<EyeTwoTone twoToneColor="#52C41A" title="Caissiers" onClick={() => { liste(props?.record)}} />} type="text" /> */ }

