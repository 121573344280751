import { Space, Modal } from 'antd';
import React ,{ useState, useEffect }from "react";
import { EditTwoTone, DeleteTwoTone ,CheckCircleTwoTone,ClockCircleTwoTone,CloseCircleTwoTone } from '@ant-design/icons';
import { IoInformation } from 'react-icons/io5'
import { useNavigate,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {updatesolde} from "../../actions/update-solde"; 
import {updateappel} from "../../actions/update-appel";
import {decreasesolde} from "../../actions/decrease-solde"; 
import UserService from "../../services/user.service"; 
import OTPInput, { ResendOTP } from "otp-input-react";
import { verifyPhoneOtp } from "../../actions/user";
import { Row, Text,Img, Column,Button} from "components";

// updatereap



export default function BankerActions(props) {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [successful, setSuccessful] = useState(false);
  const dispatch = useDispatch();
  const state = props?.record
  console.log("satate assss ",state);

  ///
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const  [montant, setMontant]  = React.useState ("");
  const  [otp, setOtp]  = React.useState ("");
  // const navigate = useNavigate();

  //
  const [balance, setBalance] = useState("");
  console.log("user :",currentUser);
  
  
  const userId = currentUser.response.user.id
 
  

  
  useEffect(() => {
    UserService.getBalance(userId).then(
      (response) => {
        setBalance(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

          setBalance(_content);
      }
    );
  }, []);
  //

 

  // const [successful, setSuccessful] = useState(false);
  const response = JSON.parse(localStorage.getItem('user'));
  // const userId = response.response.user.id
   
  const { message } = useSelector(state => state.message);
  // const dispatch = useDispatch();

  const [content, setContent] = useState("");

  ///

  const generate = () => {
   
    setIsModalOpen(true);
 
};
  


  // const userId = currentUser.response.user.id
  const receiverId = state.senderId;
  const senderId  = state.receiverId;
  const  solde = state.amount;

  const  handleSubmit =  (e) => {
    e.preventDefault();
    setSuccessful(true);
    dispatch(verifyPhoneOtp(userId, otp))
      .then(() => {
        setSuccessful(true);
        if (solde >balance.solde) {
          // console.log("futietterigegeirorttt");
          setIsModalOpen(false);
          setIsModalOpen2(true);
        }  else {
        
        // navigate("/caissiers");
        // window.location.reload();
        setIsModalOpen(false);
        setIsModalOpen3(true);
        dispatch(decreasesolde(receiverId,senderId,solde))
        dispatch(updateappel(state.id,state.montant,state.user,"SUCCESS",state.senderId,state.receiverId))
        // window.location.reload();
        // navigate("/dashboardsuperviseur");
        }
       
      })
      .catch(() => {
        setSuccessful(false);
    
      });

  };

  const  handleSubmit1 =  (e) => {
    e.preventDefault();
    setSuccessful(true);
    dispatch(verifyPhoneOtp(userId, otp))
      .then(() => {
        setSuccessful(true);
        // navigate("/caissiers");
        // window.location.reload();
        setIsModalOpen1(false);
        setIsModalOpen4(true);
        dispatch(updateappel(state.id,state.montant,state.user,"FAILED",state.senderId,state.receiverId))
        // window.location.reload();
        // navigate("/dashboardsuperviseur")
       
      })
      .catch(() => {
        setSuccessful(false);
        
      });

  };

   
  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const handleOk3 = () => {
    setIsModalOpen3(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };

  const handleOk4 = () => {
    setIsModalOpen4(false);
  };
  const handleCancel4 = () => {
    setIsModalOpen4(false);
  };


  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleOk1 = () => {
    setIsModalOpen1(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  function handleNavigate28() {
    window.location.reload();
  }

   
    const navigate = useNavigate();
    // function updateSolde(record){
    //     navigate(
    //         '/',
    //         {
    //           state: {
                
    //             receiverId: record?.receiverId,
    //             senderId:record?.senderId,
    //             solde: record?.solde,
              
    //           }
    //         }
    //       )
        
    // }
    
    const [approvement, setApprovement] = useState(props?.record?.banker_approvement?.approvement)
    const [loading, setLoading] = useState(false)
    const [sold, setSold] = useState(props?.record?.deed_of_sale?.sold)
    const [commandeConfirmed, setCommandeConfirmed] = useState(props?.record?.order?.confirmed)
    console.log('confirmed : ', commandeConfirmed)
    const status = props?.record?.status
    if (loading) return <Button type="primary" shape="circle" loading />
    if (status === 'SUCCESS') return null
    if (status === 'FAILED') return null
    
  
    return (
        <Space size="small">
            <button onClick={generate}>< CheckCircleTwoTone  twoToneColor="#52C41A" title="valider la demande" /></button>  
            <Modal  title="verification code OTP" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel} closable={false}>
     
     {message && (
     <div className="form-group">
       <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
         {message}
       </div>
     </div>
   )}
      
      
      <div class=" p-2 text-center"> 
          <Img 
              src="images/coolupay.png"
              className="lg:h-[80px] xl:h-[80px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[80%]"
              alt="logobbsOne"
            />
            <h4>veuillez entrer votre code OTP</h4>
             
             
            <br/>
            <div className="absolute content-center items-center  right-[-10] top-[20] "> 
          <OTPInput  
           inputStyles={{width: "20%",
                         height: "30px",
                               textAlign: "center",
                              fontSize: "14px",
                               fontWeight: "bold"}}
              value={otp} 
              onChange={setOtp} 
              autoFocus 
              OTPLength={4} 
              otpType="number" disabled={false}  />
              <div></div>
          </div>
          </div>
      <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
        <Button
          className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
          
          shape="RoundedBorder8"
          
          variant="FillBluegray100"
          onClick={handleOk}
        >
          Annuler
        </Button>
        <Button
          
          className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
          shape="RoundedBorder8"
          onClick={handleSubmit}
          
          disabled={successful}>
          {successful && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          Confirmer
        </Button>
      </Row>
    </Modal>
    <Modal footer={null} open={isModalOpen2} onOk={handleOk2} onCancel={handleNavigate28} closable={false}>
           <div className="m-[auto] max-h-[97vh] items-center  w-[-100%]">
          <Column className="bg-gray_102 items-center justify-start lg:p-[26px] xl:p-[32px] 2xl:p-[36px] 3xl:p-[439px] rounded-radius16 w-[100%]">
       
     
            
                   <Img
                     src="images/img_close.svg"
                     className="common-pointer test_1200x630waTwo"
                     onClick={handleNavigate28}
                     alt="close"
                   />
                    <h2> Montant demandé par le superviseur PDV :</h2>
                      <Text className="Numrodubnf" as="h7" variant="h7">
                      {solde} Fcfa
                      </Text>
                      <h4 className="columnclose2 alert alert-danger" as="h8" variant="h8">Votre Solde est insuffisant pour valider la demande </h4>
                   <Img
                     src="images/invalid.webp"
                     className="sandclockOne"
                     alt="sandclockOne"
                     width= "35%"
                   />
                   {/* <Text className="columnclose2 alert alert-danger" as="h4" variant="h4">
                           {props.message} 
                         
                     </Text> */}
                 
         
                 </Column>
      
      </div>
    
       </Modal>

       <Modal footer={null} open={isModalOpen3} onOk={handleOk3} onCancel={handleNavigate28} closable={false}>
           <div className="m-[auto] max-h-[97vh] items-center  w-[-100%]">
          <Column className="bg-gray_102 items-center justify-start lg:p-[26px] xl:p-[32px] 2xl:p-[36px] 3xl:p-[439px] rounded-radius16 w-[100%]">
       
     
            
                   <Img
                     src="images/img_close.svg"
                     className="common-pointer test_1200x630waTwo"
                     onClick={handleNavigate28}
                     alt="close"
                   />
                     <h2> Montant demandé par le superviseur PDV :</h2>
                      <Text className="Numrodubnf" as="h7" variant="h7">
                      {solde} Fcfa
                      </Text>
                    <h4 className="columnclose2 alert alert-success" as="h8" variant="h8"> Demande valider avec succes votre compte vient d'etre debite de {solde} Fcfa </h4>
                   <Img
                     src="images/valide.png"
                     className="sandclockOne"
                     alt="sandclockOne"
                     width= "35%"
                   />
                   {/* <Text className="columnclose2 alert alert-danger" as="h4" variant="h4">
                           {props.message} 
                         
                     </Text> */}
                 
         
                 </Column>
      
      </div>
    
       </Modal>
            {/* <Button icon={<ClockCircleTwoTone  twoToneColor="#ffff00" title="Update " />} type="text"  /> */}
            <button onClick={showModal1} ><CloseCircleTwoTone twoToneColor="#EB2F96"  title="Rejeter demande" /></button>  
            <Modal  title="verification code OTP" footer={null} open={isModalOpen1} onOk={handleOk1} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel1} closable={false}>
     
     {message && (
     <div className="form-group">
       <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
         {message}
       </div>
     </div>
   )}
      
      
      <div class=" p-2 text-center"> 
          <Img 
              src="images/coolupay.png"
              className="lg:h-[70px] xl:h-[80px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[70%]"
              alt="logobbsOne"
            />
            <h4>veuillez entrer votre code OTP</h4>
             
             
            <br/>
            <div className="absolute content-center items-center  right-[-10] top-[20] "> 
          <OTPInput  
           inputStyles={{width: "20%",
                         height: "30px",
                               textAlign: "center",
                              fontSize: "14px",
                               fontWeight: "bold"}}
              value={otp} 
              onChange={setOtp} 
              autoFocus 
              OTPLength={4} 
              otpType="number" disabled={false}  />
              <div></div>
          </div>
          </div>
      <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
        <Button
          className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
          
          shape="RoundedBorder8"
          
          variant="FillBluegray100"
          onClick={handleOk1}
        >
          Annuler
        </Button>
        <Button
          
          className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
          shape="RoundedBorder8"
          onClick={handleSubmit1}
          
          disabled={successful}>
          {successful && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          Confirmer
        </Button>
      </Row>
    </Modal>

    <Modal footer={null} open={isModalOpen4} onOk={handleOk4} onCancel={handleNavigate28} closable={false}>
           <div className="m-[auto] max-h-[97vh] items-center  w-[-100%]">
          <Column className="bg-gray_102 items-center justify-start lg:p-[26px] xl:p-[32px] 2xl:p-[36px] 3xl:p-[439px] rounded-radius16 w-[100%]">
       
     
            
                   <Img
                     src="images/img_close.svg"
                     className="common-pointer test_1200x630waTwo"
                     onClick={handleNavigate28}
                     alt="close"
                   />
                    <h2> Montant demandé par le superviseur PDV :</h2>
                      <Text className="Numrodubnf" as="h7" variant="h7">
                      {solde} Fcfa
                      </Text>
                      <h4 className="columnclose2 alert alert-danger" as="h8" variant="h8"> Demande rejeter </h4>
                   <Img
                     src="images/invalid.webp"
                     className="sandclockOne"
                     alt="sandclockOne"
                     width= "35%"
                   />
                   {/* <Text className="columnclose2 alert alert-danger" as="h4" variant="h4">
                           {props.message} 
                         
                     </Text> */}
                 
         
                 </Column>
      
      </div>
    
       </Modal>
     
        </Space>
    )

}

//onClick={handleSubmit}
 