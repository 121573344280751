import React ,{ useState, useEffect }from "react";
import UserService from "../../services/user.service";
import {updatesuperviseur} from "../../actions/update-superviseur";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate  } from 'react-router-dom';
import Form from "react-validation/build/form";
import Dropdown  from "components/Dropdown";
import {
  Column,
  Row,
  Button,
  Img,
  Text,
  Line,
  Input,
  List,
  Stack,
} from "components";
import Sidebar from "components/Sidebar";
import { Modal } from 'antd';
import {useLocation} from "react-router-dom";

const ModifyCaissierPage = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [balance, setBalance] = useState("");
  console.log("user :",currentUser);
  
  const userId = currentUser.response.user.id
 
  

  const classNameSolde = "text-amber_A700 font-montserrat font-bold lg:text-[21px] xl:text-[27px] 2xl:text-[30px] 3xl:text-[36px]";
  const _classNameSolde = "text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px]";
  if (currentUser==null) {
    <Navigate to="/dashboardsuperviseur" />;
    
  }
  useEffect(() => {
    UserService.getBalance(userId).then(
      (response) => {
        setBalance(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

          setBalance(_content);
      }
    );
  }, []);

  const navigate = useNavigate();
  const { state } = useLocation();
  
  //value={CNI.value}
  //onChange={onChangeCNI}

  const response = JSON.parse(localStorage.getItem('user'));
  const use = response.response.user.username
  
 
  const [isModalOpen, setIsModalOpen]  = useState(false);

  const id = state.id;
  const cni  = state.cni;
  const firstname = state.firstName;
  const lastname = state.lastName;
  const Username = state.username;
  const Adresse = state.adresse;
  const Phone = state.phone;
  const Email = state.email;
  const rep = JSON.parse(localStorage.getItem('user'));
  const created_by = rep.response.user.id

  
  
  // console.log("state cni :",cni);
  
  const  [firstName, setfirstName]  = React.useState ({value: firstname,

    setValue: (newValue) => {
      setfirstName({...firstName, value: newValue})
      console.log(firstName.value) 
  }} );
  const  [lastName, setlastName]  = React.useState  ({value: lastname,

    setValue: (newValue) => {
      setfirstName({...lastName, value: newValue})
      console.log(lastName.value) 
  }} );
  const  [username, setUsername]  = React.useState  ({value: Username,

    setValue: (newValue) => {
      setUsername({...username, value: newValue})
      console.log(username.value) 
  }} );

  const  [adresse, setAdresse]  = React.useState ({value: Adresse,

    setValue: (newValue) => {
      setAdresse({...adresse, value: newValue})
      console.log(adresse.value) 
  }} );

  const  [phone, setPhone]  = React.useState ({value: Phone,

    setValue: (newValue) => {
      setAdresse({...phone, value: newValue})
      console.log(phone.value) 
  }} );

  const  [CNI, setCNI]  = React.useState ({value: cni,

    setValue: (newValue) => {
      setCNI({...CNI, value: newValue})
      console.log(CNI.value) 
  }} );

  const  [email, setEmail]  = React.useState ({value: Email,

    setValue: (newValue) => {
      setEmail({...email, value: newValue})
      console.log(email.value) 
  }} );
  
   
  const [successful, setSuccessful] = useState(false);
  const dispatch = useDispatch();
  //setCNI(state.cni);
   
  const onChangefirstName = (e) => {
    const firstName = e.target.value;
    setfirstName(firstName);
  };

  const onChangelastName = (e) => {
    const lastName = e.target.value;
    setlastName(lastName);
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeAdresse = (e) => {
    const adresse = e.target.value;
    setAdresse(adresse);
  };

  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };
  const onChangeCNI = (e) => {

    const CNI = e.target.value;
    setCNI(CNI);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };


  const  handleSubmit =  (e) => {
    e.preventDefault();
    setSuccessful(true);
    dispatch(updatesuperviseur(id,firstName.value || firstName, lastName.value || lastName, username.value || username, phone.value || phone, CNI.value || CNI, email.value || email, adresse.value || adresse, created_by))
      .then(() => {
        setSuccessful(true);
        navigate("/caissiers");
        window.location.reload();
       
      })
      .catch(() => {
        setSuccessful(false);
    
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };



  function handleNavigate32() {
    navigate("/superviseurs");
  }

  function handleNavigate31() {
    navigate("/infoscaissier");
  }

  function handleNavigate33() {
    navigate("/dashboardsuperviseur");
  }


  return (
    <>
      <Column className="bg-bluegray_50 font-montserrat items-center justify-start mx-[auto] w-[100%]">
        <Row className=" justify-between w-[98%]">
          <Sidebar className="w-[18%]" onDashboardClick={handleNavigate33} />
          <Column className="bg-white_A700 items-center lg:py-[25px] xl:py-[32px] 2xl:py-[36px] 3xl:py-[43px] rounded-bl-[0] rounded-br-[0] rounded-tl-[12px] rounded-tr-[12px] shadow-bs2 w-[63%]">
            <Column className="items-center justify-start xl:mb-[115px] 2xl:mb-[129px] 3xl:mb-[155px] lg:mb-[92px] w-[100%]">
              <Row className="items-start w-[92%]">
                <Button
                  className="flex lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] items-center justify-center mt-[1px] rounded-radius50 lg:w-[21px] xl:w-[27px] 2xl:w-[30px] 3xl:w-[36px]"
                  size="smIcn"
                  variant="icbFillGray201"
                >
                  <Img
                    src="images/img_arrowleft.svg"
                    className="flex items-center justify-center"
                    onClick={handleNavigate32}
                    alt="arrowleft"
                  />
                </Button>
                <Text className="Modifieruncai" as="h5" variant="h5">
                  Modifier un superviseur
                </Text>
                <Text
                  className="font-bold font-inter mb-[1px] lg:ml-[144px] xl:ml-[181px] 2xl:ml-[203px] 3xl:ml-[244px] text-black_900 w-[auto]"
                  as="h4"
                  variant="h4"
                >
                  <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                    Solde{" "}
                  </span>
                  <span  className={_classNameSolde} >
                     {new Intl.NumberFormat('de-DE').format(balance.solde)} Fcfa
                  </span>
                </Text>
                <Img
                  src="images/img_ueyeslash.svg"
                  className="ueyeslash"
                  alt="ueyeslash"
                />
              </Row>
              <Line className="bg-black_900_19 h-[1px] lg:mt-[26px] xl:mt-[33px] 2xl:mt-[37px] 3xl:mt-[44px] w-[100%]" />
              <Column className="justify-start lg:mt-[31px] xl:mt-[39px] 2xl:mt-[44px] 3xl:mt-[53px] w-[93%]">
              <Form  >
              <Text
                  className="text-black_900_cc w-[auto]"
                  as="h6"
                  variant="h6"
                >
                  Nom Superviseur
                </Text>
                <Input
                  className="placeholder:text-black_900_b2 GroupSeventeen"
                  wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                  name="GroupSeventeen"
                  //value={CNI.value}
                  value={firstName.value}
                  onChange={onChangefirstName}
                ></Input>
                 <br/>
              <Text
                  className="text-black_900_cc w-[auto]"
                  as="h6"
                  variant="h6"
                >
                  Prenom Superviseur
                </Text>
                <Input
                  className="placeholder:text-black_900_b2 GroupSeventeen"
                  wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                  name="GroupSeventeen"
                   
                  value={lastName.value}
                  onChange={onChangelastName}
                ></Input>
                 <br/>
                <Text
                  className="text-black_900_cc w-[auto]"
                  as="h6"
                  variant="h6"
                >
                  Nom d'utillisateur
                </Text>
                <Input
                  className="placeholder:text-black_900_b2 GroupSeventeen"
                  wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                  name="GroupSeventeen"
                  value={username.value}
                  onChange={onChangeUsername}
                ></Input>
              
                <Text className="labelCounter" as="h6" variant="h6">
                  Numero du caissier
                </Text>
                <Input
                  className="placeholder:text-black_900_b2 GroupSeventeen"
                  wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                  name="GroupFourteen"
                  value={phone.value}
                  onChange={onChangePhone}
                ></Input>
                 <Text className="labelCounter" as="h6" variant="h6">
                  Adresse du caissier
                </Text>
                <Input
                  className="placeholder:text-black_900_b2 GroupSeventeen"
                  wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                  name="GroupSixteen"
                  value={adresse.value}
                  onChange={onChangeAdresse}
                ></Input>
                 <Text className="labelCounter" as="h6" variant="h6">
                  CNI
                </Text>
                <Input
                  className="placeholder:text-black_900_b2 GroupSeventeen"
                  wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                  name="GroupFourteen"
                  value={CNI.value}
                  onChange={onChangeCNI}
                ></Input>
                 
                 <Text className="labelCounter" as="h6" variant="h6">
                  EMAIL
                </Text>
                <Input
                  className="placeholder:text-black_900_b2 GroupSeventeen"
                  wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                  name="GroupFourteen"
                  value={email.value}
                  onChange={onChangeEmail}
                ></Input>
               
                <Button
                  className="common-pointer font-semibold lg:mt-[43px] xl:mt-[54px] 2xl:mt-[60px] 3xl:mt-[73px] lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px] text-center w-[100%]"
                  onClick={handleSubmit}
                  shape="RoundedBorder8"
                  size="lg"
                >
                  Modifier
                </Button>
                </Form>
              </Column>
            </Column>
          </Column>
          <Column className="items-center w-[15%]">
          <Row className="bg-white_A700 items-center justify-between lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">
              {/* <Button
                className="flex items-center justify-center uexchange_One"
                variant="icbFillGray200"
              >
                <Img
                  src="images/img_settings.svg"
                  className="flex items-center justify-center lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px]"
                  alt="settings"
                />
              </Button> */}
              <Dropdown color="white" />
              <Text
                className="text-bluegray_600 font-semibold text-black_900 w-[auto]"
                variant="body1"
              >
                <strong>{use}</strong>
              </Text>
              <Img
                src="images/img_ellipse1.png"
                className="lg:h-[26px] xl:h-[33px] 2xl:h-[37px] 3xl:h-[44px] rounded-radius50 lg:w-[25px] xl:w-[32px] 2xl:w-[36px] 3xl:w-[43px]"
                alt="EllipseOne"
              />
            </Row>
            <List
              className="gap-[0] min-h-[auto] xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[100%]"
              orientation="vertical"
            >
              <Column className="listfluxdetransac">
                <Column className="justify-start mt-[2px] w-[94%]">
                  <Text
                    className="font-medium text-black_900 w-[auto]"
                    variant="body2"
                  >
                    Flux de transactions
                  </Text>
                  <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                    <Img
                      src="images/img_vector2.png"
                      className="VectorTwo"
                      alt="VectorTwo"
                    />
                    <Img
                      src="images/img_vector1.svg"
                      className="VectorOne"
                      alt="VectorOne"
                    />
                  </Stack>
                  <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      lun
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mar
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mer
                    </Text>
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      jeu
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      ven
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      sam
                    </Text>
                    <Text
                      className="text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      dim
                    </Text>
                  </Row>
                </Column>
              </Column>
              <Column className="listfluxdetransac">
                <Column className="justify-start mt-[2px] w-[94%]">
                  <Text
                    className="font-medium text-black_900 w-[auto]"
                    variant="body2"
                  >
                    Flux de transactions
                  </Text>
                  <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                    <Img
                      src="images/img_vector2.png"
                      className="VectorThree"
                      alt="VectorThree"
                    />
                    <Img
                      src="images/img_vector1.svg"
                      className="VectorOne"
                      alt="VectorFour"
                    />
                  </Stack>
                  <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      lun
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mar
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mer
                    </Text>
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      jeu
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      ven
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      sam
                    </Text>
                    <Text
                      className="mb-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      dim
                    </Text>
                  </Row>
                </Column>
              </Column>
            </List>
            <Column className="bg-white_A700 justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius16 w-[100%]">
              <Text className="columnfluxdetransac_two" variant="body2">
                Flux de transactions
              </Text>
              <Column className="items-center justify-start lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] mx-[auto] xl:p-[11px] 2xl:p-[12px] 3xl:p-[14px] lg:p-[8px] w-[89%]">
                <Stack className="2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] w-[85%]">
                  <div className="absolute bg-blue_A700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <div className="absolute bg-cyan_400 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <div className="absolute bg-yellow_700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <Column className="absolute bottom-[19%] justify-start right-[10%] w-[73%]">
                    <Row className="items-start justify-between w-[100%]">
                      <Text className="Thirty" variant="body2">
                        30%
                      </Text>
                      <Text className="Forty" variant="body2">
                        40%
                      </Text>
                    </Row>
                    <Text className="columnthirty" variant="body2">
                      30%
                    </Text>
                  </Column>
                </Stack>
              </Column>
            </Column>
            <Button
              className="2xl:mt-[85px] 3xl:mt-[102px] flex items-center justify-center lg:mt-[60px] text-center w-[92%] xl:mt-[76px]"
              leftIcon={
                <Img
                  src="images/img_camera_24X24.svg"
                  className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                  alt="camera"
                />
              }
              shape="RoundedBorder8"
              size="lg"
            >
              <div className="bg-transparent font-bold xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]">
                Réapprovisionner
              </div>
            </Button>
          </Column>
        </Row>
      </Column>

      <Modal  title="Information du Caissier" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel}>
         
              <Text
                className="font-semibold text-black_900_cc w-[auto]"
                as="h6"
                variant="h6"
              >
                Name Caissier
              </Text>
              <Text className="Retrait" as="h8" variant="h8">
              {state.name}
              </Text>
              <Text  className="font-semibold text-black_900_cc w-[auto]"
                as="h6"
                variant="h6" >
                Adresse du Caissier
              </Text>
              <Text className="test_221772346" as="h8" variant="h8">
              {state.address}
              </Text>
              <Text  className="font-semibold text-black_900_cc w-[auto]"
                as="h6"
                variant="h6">
                Numero_telephone
              </Text>
              <Text className="" as="h8" variant="h8">
              {state.numero_telephone}
              </Text>
             
              <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
                <Button
                  className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                  
                  shape="RoundedBorder8"
                  
                  variant="FillBluegray100"
                  onClick={handleOk}
                >
                  Annuler
                </Button>
                <Button
                  className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                  shape="RoundedBorder8"
                  onClick={handleNavigate32}
                >
                  Confirmer
                </Button>
              </Row>
              
           
         
      </Modal>
    </>
  );
};

export default ModifyCaissierPage;
