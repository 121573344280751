
import {
  Button,
  VStack,
} from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";
export default function Actions() {
  const navigate = useNavigate();
  function handleNavigate() {
    navigate("/dashboardsuperviseur");
  }
  
  

  return (
    <VStack py={8} px={5} spacing={3}>
      <Button onClick={handleNavigate} w="full" variant="outline">
        Retour 
      </Button>
    
    </VStack>
  )
}
