import {
  VALIDE_SUCCESS,
  VALIDE_FAIL,
  SET_MESSAGE,
} from "./types";
import BlockedService from "../services/blocked";
export const blocked = (id, userId) => (dispatch) => {
  console.log("id and userId in action", id, userId);

  return BlockedService.blocked(id, userId).then(
    (response) => {
      dispatch({
        type: VALIDE_SUCCESS,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: VALIDE_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};