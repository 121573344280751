import React from "react";
import { Navigate,useNavigate  } from 'react-router-dom';
//, Text, Line
import { Column, Img, Row, Line } from "components";
//import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

const Sidebar = (props) => {

  const navigate = useNavigate();
  function handleNavigate29() {
    navigate("/dashboardsuperviseur");
  }
  const activeLink = "text-bluegray_600 font-semibold xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px]  w-[auto]";
  const normalLink = "common-pointer rowcar";
  return (
    <>
      <aside className={props.className}>
        <div className="">
          <Column className="bg-white_A700 justify-start lg:pr-[10px] xl:pr-[13px] 2xl:pr-[15px] 3xl:pr-[18px] lg:py-[10px] xl:py-[13px] 2xl:py-[15px] 3xl:py-[18px] shadow-bs w-[100%]">
            <Column className="justify-start lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] w-[100%]">
            
            <Img
                src="images/coolupay.png"
                className="lg:h-[50px] xl:h-[70px] 2xl:h-[42px] 3xl:h-[51px] w-[80%]"
                alt="logobbsOne"
                onClick={handleNavigate29}
              />
              <Row className="items-start 2xl:mt-[108px] 3xl:mt-[130px] lg:mt-[77px] xl:mt-[96px] w-[100%]">
             
                <Img
                  src="images/img_mobile_24X24.svg"
                  className="car"
                  alt="mobile"
                />
                <NavLink
                to = '/dashboardsuperviseur'
                className={({ isActive }) =>
                isActive ? activeLink :normalLink
              }
               
              >
               
               Dashboard
             
              </NavLink>
              
              </Row>
            
            
            <Row className="items-center lg:mt-[22px] xl:mt-[27px] 2xl:mt-[31px] 3xl:mt-[37px] w-[100%]">
             
              <Img
                src="images/img_car.svg"
                className="car"
                  alt="mobile"
              />
                <NavLink
                to = '/superviseurs'
                className={({ isActive }) =>
                isActive ? activeLink :normalLink
              }
               
              >  
                Superviseurs PDV
             
                  </NavLink>
            </Row>
            </Column>
            <Column className="justify-start lg:mb-[244px] xl:mb-[305px] 2xl:mb-[344px] 3xl:mb-[412px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[21px] xl:mt-[27px] 2xl:mt-[30px] 3xl:mt-[36px] w-[78%]">
              <Row className="items-start w-[100%]">

                <Img
                  src="images/img_umoneywithdra.svg"
                  className="car"
                  alt="mobile"
                />
                  <NavLink
                  to = '/appels'
                  className={({ isActive }) =>
                  isActive ? activeLink :normalLink
              }
               
              >
               
               Appel de Fond
             
                  </NavLink>
              </Row>

       

              
              </Column>
              <Column className="justify-start lg:mb-[244px] xl:mb-[305px] 2xl:mb-[344px] 3xl:mb-[412px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[21px] xl:mt-[27px] 2xl:mt-[30px] 3xl:mt-[36px] w-[78%]">
              </Column>
            </Column>
           
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
